import * as React from "react"

// import Lazy from'apps/penelopepenny/lazy'
// import App from'apps/penelopepenny'
// import Canvas from'apps/penelopepenny/canvas'

import Atropos from 'apps/atropos'


const PageHome=(props)=>{
  // React.useEffect(()=>{
  //   alert('home 2')
  // },[])

  // console.log('testttttt')

  // return <Canvas/>
  // return <App/>
  // return <Lazy/>
  return <Atropos/>
}

export default PageHome
